import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { DataProvider } from "./contexts/DataContext";
import Topbar from "./pages/global/Topbar";
import Navbar from "./pages/global/Navbar";
import { ColourModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import KPIs from "./pages/KPIs";
import Dashboard from "./pages/Dashboard";
import Office from "./pages/Office";
import Calendar from "./pages/Calendar";
import Messaging from "./pages/Messaging";
import Tasks from "./pages/Tasks";
import Forms from "./pages/Forms";
import Apps from "./pages/Apps";
import Reports from "./pages/Reports";
import Requests from "./pages/Requests";
import Development from "./pages/Development";
import Team from "./pages/Team";
import Directory from "./pages/Directory";
import Projects from "./pages/Projects";

const App = () => {
  const [theme, colourMode] = useMode();

  return (
    <AuthProvider>
      <DataProvider>
        <ColourModeContext.Provider value={colourMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              <Navbar />
              <main className="content">
                <Topbar />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/office" element={<Office />} />
                  <Route path="/office/calendar" element={<Calendar />} />
                  <Route path="/office/kpis" element={<KPIs />} />
                  <Route path="/office/projects" element={<Projects />} />
                  <Route path="/office/messaging" element={<Messaging />} />
                  <Route path="/office/tasks" element={<Tasks />} />
                  <Route path="/office/forms" element={<Forms />} />
                  <Route path="/office/apps" element={<Apps />} />
                  <Route path="/office/reports" element={<Reports />} />
                  <Route path="/office/requests" element={<Requests />} />
                  <Route path="/development" element={<Development />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/directory" element={<Directory />} />
                </Routes>
              </main>
            </div>
          </ThemeProvider>
        </ColourModeContext.Provider>
      </DataProvider>
    </AuthProvider>
  );
};

export default App;
