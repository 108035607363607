import React from "react";
import { Box } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Header from "../components/Header";
import Container from "../components/Container";
import InputButton from "../components/InputButton";
import ReportsInput from "../forms/ReportsInput";

const Reports = () => {
    return (
        <>
        <Box m="20px">
          <Box
           display="flex"
           justifyContent="space-between"
          >
            <Header title="REPORTS" subtitle="Create and View Office Reports"/>
            <InputButton 
            position="fixed"
            form={<ReportsInput/>}
            icon={<AddOutlinedIcon/>}
            width="45%"
            height="auto"
            top="10vh"
            bottom="none"
            right="5%"
            left="none"
            />
          </Box>
        </Box>
        <Box 
        m="20px" 
        height="75vh" 
        display="grid" 
        gridTemplateColumns="1fr 2fr"
      >
        <Container 
          title="Menu"
        />
        <Container 
          title="Body"
        />
      </Box>
      </>
        )
}

export default Reports