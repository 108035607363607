import React from 'react'
import { Box } from '@mui/material'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { savedEvents } from '../data/testData';
import { handleDateClick, handleEventClick } from '../functions';
// import { DataContext } from '../contexts/DataContext';

const CalendarMain = ({events, setEvents}) => {
  // const {state} = useContext(DataContext)
  // const {filteredCalendarData} = state
  
return (
  <Box m="20px">
    <FullCalendar
      height="100%"
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ]}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
      }}
      initialView="dayGridMonth"
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      select={handleDateClick}
      eventClick={handleEventClick}
      eventsSet={(events) => setEvents(events)}
      initialEvents={savedEvents}
    />
  </Box>
);
};

export default CalendarMain;