import React, {useState} from "react";
import axios from "axios";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { devApi, prodApi } from "../data/api";

const url = prodApi.reports

const userSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    company: yup.string().required("Required"),
    email: yup.string().email("Invalid Email Address").required("Required"),
    phone: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    country: yup.string().required("Required"),
})

const DirectoryInput = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [data, setData] = useState(
        {
            title: "",
            description: "",
            body: "",
        }
    )
    
    const handleSubmit = (values) => {
    axios
      .post(url, values)
      .then((res) => {
        console.log("Success", res.data);
        setData({
            title: "",
            description: "",
            body: "",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

    return (
        <Box m="30px">
            <Header title="NEW REPORT" subtitle="Add a new report"/>

            <Formik
                onSubmit={handleSubmit}
                initialValues={data}
                validationSchema={userSchema}
            >
                {({ 
                    values, 
                    errors, 
                    touched, 
                    handleBlur, 
                    handleChange, 
                    handleSubmit 
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Title"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.title}
                                name="title"
                                error={!!touched.title && !!errors.title}
                                helperText={touched.title && errors.title}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name="description"
                                error={!!touched.description && !!errors.description}
                                helperText={touched.description && errors.description}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="body"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.body}
                                name="body"
                                error={!!touched.body && !!errors.body}
                                helperText={touched.body && errors.body}
                                sx={{ gridColumn: "span 4" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Submit Report
                            </Button>
                        </Box>
                    </form>
                )}

            </Formik>
        </Box>
    )
}

export default DirectoryInput