import React, { useContext, useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { AuthContext } from "../contexts/AuthContext";
import { tokens } from "../theme";
import { devApi, prodApi } from "../data/api";

const loginUrl = prodApi.login;
const userUrl = prodApi.user;

const userSchema = yup.object().shape({
  email: yup.string().email("Invalid Email Address").required("Required"),
  password: yup.string().required("Required"),
})

const LoginModal = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme()
  const colours = tokens(theme.palette.mode);

  const { loggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [data, setData] = useState({
    email:"",
    password:"",
  })
  const [error, setError] = useState("");

  if (error) {
    console.log(error);
  }

  const handleLogin = async (values) => {
    try {  
      // Send login request to the backend
      const tokenResponse = await axios.post(loginUrl, values);
      const { token } = tokenResponse.data;
  
      // Store the token in local storage
      try {
        localStorage.setItem("token", token);
      } catch (localStorageError) {
        console.error("Error storing token in local storage:", localStorageError);
        setError("An error occurred while storing the token.");
        return;
      }
  
      // Get User Info with Token
      try {
        const userResponse = await axios.get(userUrl, {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
          withCredentials: true, // Use boolean value here
        });
  
        // Store user data in local storage
        try {
          localStorage.setItem("user", JSON.stringify(userResponse.data));
        } catch (localStorageError) {
          console.error("Error storing user data in local storage:", localStorageError);
          setError("An error occurred while storing user data.");
          return;
        }
  
        // Perform login logic
        setLoggedIn(true);
        setIsLoggedIn(userResponse.data);
      } catch (userRequestError) {
        if (userRequestError.response && userRequestError.response.status === 401) {
          setError("Invalid username or password.");
        } else {
          setError("An error occurred while fetching user data.");
        }
        console.error("Error fetching user data:", userRequestError);
      }
    } catch (loginError) {
      if (loginError.response && loginError.response.status === 401) {
        setError("Invalid username or password.");
      } else {
        setError("An error occurred during login. Please try again later.");
      }
      console.error("Login error:", loginError);
    }
  };
  
  // const handleLogin = async (values) => {
  //   try {  
  //     // Send login request to the backend
  //     const tokenResponse = await axios.post(loginUrl, values);
  //     const { token } = tokenResponse.data;

  //     // console.log("Token Response:", tokenResponse);

  //     // Store the token in local storage
  //     localStorage.setItem("token", token);

  //     // Get User Info with Token
  //     const userResponse = await axios.get(userUrl, {
  //       headers: {
  //         "Authorization": `Bearer ${token}`,
  //       },
  //       withCredentials: "Include",
  //     });

  //     // Store user data in local storage
  //     localStorage.setItem("user", JSON.stringify(userResponse.data));

  //     // Perform login logic
  //     setLoggedIn(true);
  //     setIsLoggedIn(userResponse.data);
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       setError("Invalid username or password.");
  //     } else {
  //       setError("An error occurred. Please try again later.");
  //     }
  //     console.error("e no dey work", error);
  //   }
  // };

  const handleChange = (values) => {
    setData(...data, values)
  }

  if (loggedIn) {
    return null;
  }

  return (
    <>
      <Box
        m="auto"
        height="100vh"
        width="100vw"
        backgroundColor={colours.primary[500]}
        zIndex= "3"
        position="absolute"
      >
        <Box
          width="20%"
          m="auto"
          position="absolute"
          top="30%"
          left="40%"
          justifyContent="center"
          alignItems="center"
          sx={{ 
            transform:"translate{-50%, -50%}",
           }}
        >
          <Box
            display="flex"
            justifyContent="center"
          >
          <Typography
            variant="h3"
          >
            LOGIN
          </Typography>
          </Box>

          <Formik
            onSubmit={handleLogin}
            onChange={handleChange}
            initialValues={data}
            validationSchema={userSchema}
          >
                {({ 
                    values, 
                    errors, 
                    touched, 
                    handleBlur, 
                    handleChange, 
                    handleSubmit 
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      mt="20px"
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                      <TextField 
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 4" }}
                      />
                      <TextField 
                        fullWidth
                        variant="filled"
                        type="password"
                        label="Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 4" }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center" mt="20px">
                      <Button type="submit" color="secondary" variant="contained">
                          Login
                      </Button>
                    </Box>
                  </form>
                )}

            </Formik>
        </Box>
      </Box>
    </>
  );
};

export default LoginModal;
