import React, { useContext } from "react"
import { Box, IconButton, useTheme} from "@mui/material"
import { ColourModeContext, tokens } from "../../theme"
import InputBase from "@mui/material/InputBase"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined"
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import SearchIcon from "@mui/icons-material/Search"
import InputButton from "../../components/InputButton"
import ProfileInput from "../../forms/ProfileInput"

const Topbar = () => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode)
  const colourMode = useContext(ColourModeContext)

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box 
        display="flex" 
        backgroundColor={colours.primary[400]}
        borderRadius="3px">
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon/>
        </IconButton>
      </Box>
      <Box display="flex">
        <IconButton onClick={colourMode.toggleColourMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <InputButton 
          position="fixed"
          form={<ProfileInput/>}
          icon={<PersonOutlinedIcon/>}
          width="20%"
          height="auto"
          top="5vh"
          bottom="none"
          right="2.5%"
          left="none"
        />
      </Box>
    </Box>
  )
}

export default Topbar