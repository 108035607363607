import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Container from "../components/Container";

const Projects = () => {
    return (
      <>
        <Box 
          m="20px"
        >
          <Header title="PROJECTS - MAPPING" subtitle="Check the Status of your Projects around the World"/>
        </Box>
        <Box 
          m="20px" 
          height="75vh" 
          display="grid" 
          gridTemplateColumns="2fr 1fr"
        >
          <Container 
            title="Mapping"
          />
          <Container 
            title="Card"
          />
        </Box>
      </>
    )
  }
  
  export default Projects