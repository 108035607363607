import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../theme'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import Header from '../components/Header'
import { fetchDataSQLSecure, transformData } from '../functions'
import { devApi, prodApi } from '../data/api'

const url = prodApi.team;

const Directory = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDataSQLSecure(url);
        setData(data);
      } catch (error) {
        console.error("Error fetching client data:", error);
        throw error;
      }
    };

    fetchData();
  }, []); 

  const theme = useTheme()
  const colours = tokens(theme.palette.mode)

  const columns = [
    {field: "id", headerName: "ID"},
    {field: "name", headerName: "Name", flex: 1, cellClassName: 'name-column--cell'},
    {field: "email", headerName: "Email", flex: 1},
    {field: "phone", headerName: "Phone Number", flex: 1},
    {field: "accessLevel", headerName: "Access Level", flex: 1, renderCell: ({row: {accessLevel}}) => {
      return (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={
            accessLevel === "admin"
            ? colours.greenAccent[600]
            : accessLevel === "manager"
            ? colours.greenAccent[700]
            : colours.greenAccent[700]
          }
          borderRadius="4px"
        >
          {accessLevel === "admin" && <AdminPanelSettingsOutlinedIcon />}
          {accessLevel === "manager" && <SecurityOutlinedIcon />}
          {accessLevel === "user" && <LockOpenOutlinedIcon />}
          <Typography color={colours.grey[100]} sx={{ ml: "5px" }}>
            {accessLevel}
          </Typography>
        </Box>
      )
    }}
  ]

  return (
    <Box m="20px">
      <Header title="TEAM" subtitle="Managing the Team Members"/>
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colours.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colours.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colours.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colours.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colours.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={transformData(data)}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default Directory