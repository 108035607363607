import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";

const Tasks = () => {
    return (
        <Box m="20px">
            <Header title="TASKS" subtitle="Your Checklist"/>
        </Box>
        )
}

export default Tasks