import React from 'react'
import { Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme, 
} from '@mui/material'
import { formatDate } from '@fullcalendar/core'
import { tokens } from '../theme';

const CalendarSidebar = ({events}) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);

    return (
        <Box
        flex="1 1 20%"
        height="64.5vh"
        backgroundColor={colours.primary[400]}
        p="15px"
        borderRadius="4px"
        sx={{ 
            overflowY:"scroll",
         }}
        >
        <Typography variant="h6">Events</Typography>
        <List>
            {events.map((event) => (
            <ListItem
                key={event.id}
                sx={{
                backgroundColor: colours.greenAccent[500],
                margin: "10px 0",
                borderRadius: "2px",
                }}
            >
                <ListItemText
                primary=
                {<Typography>
                {event.title}
                </Typography>}
                secondary={
                    <Typography variant='body2'>
                    {formatDate(event.date, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                    })}
                    </Typography>
                }
                />
                <Typography variant='body2'>
                    {event.calendar}
                </Typography>
            </ListItem>
            ))}
        </List>
        </Box>
    );
  };
  
  export default CalendarSidebar;