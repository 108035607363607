import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";

const Apps = () => {
    return (
        <Box m="20px">
            <Header title="APPS" subtitle="In House Web Applications"/>
        </Box>
        )
}

export default Apps