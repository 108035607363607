import React, {useState, useContext} from 'react'
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {  Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import LoginModal from '../../modals/LoginModal';
import { AuthContext } from '../../contexts/AuthContext';

const backgroundImage = "../../images/background.png"

const Item = ({ title, to, icon, selected, setSelected}) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colours.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography variant="body2">{title}</Typography>
      <Link to={to}/>
    </MenuItem>
  )
}

const Navbar = () => {
  const {loggedIn, isLoggedIn, setLoggedIn, setIsLoggedIn} = useContext(AuthContext)
  const theme = useTheme();
  const colours = tokens(theme.palette.mode)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [selected, setSelected] = useState("Office")

  let logoutTimeout;

  const handleIdleLogout = () => {
    // Perform logout logic here
    clearTimeout(logoutTimeout);
    logoutTimeout = setTimeout(handleLogout, 5 * 60 * 1000);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
    setIsLoggedIn(null);
  }

  document.addEventListener("mousemove", handleIdleLogout);
  document.addEventListener("keypress", handleIdleLogout);

  return (
    <>
    <Box
    sx={{
      "& .pro-sidebar-inner": {
        backgroundColor: `${colours.primary[400]} !important`,
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !important",
      },
      "& .pro-inner-item": {
        padding: "5px 35px 5px 20px !important",
      },
      "& .pro-inner-item:hover": {
        color: "#868dfb !important",
      },
      "& .pro-menu-item.active": {
        color: "#6870fa !important",
      },
    }}
    >
<ProSidebar
        collapsed={isCollapsed}
        image={backgroundImage}
        >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colours.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h4" color={colours.grey[100]}>
                  6334 Group
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && loggedIn && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={isLoggedIn.avatar}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  color={colours.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {isLoggedIn.firstName} {isLoggedIn.lastName}
                </Typography>
                <Typography variant="h7" color={colours.greenAccent[500]}>
                  {isLoggedIn.title}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>         
            <Item
              title="Dashboard"
              to="/"
              icon={<SpaceDashboardOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="body2"
              color={colours.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography>
            <Item
              title="Office"
              to="/office"
              icon={<FaxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Development"
              to="/development"
              icon={<ConstructionOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="body2"
              color={colours.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography>
            <Item
              title="KPIs"
              to="/office/kpis"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Directory"
              to="/directory"
              icon={<FolderSharedOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="body2"
              color={colours.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Apps
            </Typography>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
    {!loggedIn && <LoginModal />}
    </>      
  )
}

export default Navbar