import React, { useState, useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const MultiselectDropdown = ({
  id,
  label,
  options,
  placeholder,
  onChange,
  selectedValues,
  maxDisplayedItems = 3
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const theme = useTheme()
  const colours = tokens(theme.palette.mode);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const updatedSelectedValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];

    onChange(updatedSelectedValues);
  };

  const getSelectedText = () => {
    if (selectedValues.length === 1) {
      return selectedValues[0];
    } else if (
      selectedValues.length > 1 &&
      selectedValues.length <= maxDisplayedItems
    ) {
      return selectedValues.join(", ");
    } else if (selectedValues.length > maxDisplayedItems) {
      return `${selectedValues.length} items selected`;
    }
    return "";
  };

  return (
    <Box 
      ref={dropdownRef}
      position="relative"
      display="inline-block"
      width="100%"
      m="auto"
    >
      <label htmlFor={id}>{label}</label>
      <Box
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderRadius="4px"
          padding="8px" 
          onClick={handleToggle}
          sx={{ 
            cursor: "pointer",
           }}
          >
          <Box
            color={colours.grey[100]} 
            fontSize=".8em"
            fontWeight="300"
          >
            {getSelectedText() || placeholder}
          </Box>
          <div className="dropdown-arrow">&#9662;</div>
        </Box>
        {isOpen && (
          <Box
            position="absolute"
            width="100%"
            maxHeight="200px"
            backgroundColor={colours.primary[400]}
            border="1px solid"
            borderRadius="5px"
            boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
            zIndex="5"
            sx={{ 
              overflowY:"auto",
             }}
          >
            <ul>
              {options.map((option) => (
                <li key={option} onClick={() => handleOptionClick(option)}>
                  <input
                    type="checkbox"
                    checked={selectedValues.includes(option)}
                    readOnly
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MultiselectDropdown;
