import React from 'react'
import { Box } from '@mui/material'
import Header from '../components/Header'

const KPIs = () => {
  return (
    <Box m="20px">
      <Header title="KPIs" subtitle="Productivity Metrics"/>
    </Box>
  )
}

export default KPIs