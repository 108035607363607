import React, {useState, useEffect} from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { tokens } from '../theme'
import Header from '../components/Header'
import InputButton from '../components/InputButton';
import { fetchDataSQLSecure, transformData } from '../functions';
import DirectoryInput from '../forms/DirectoryInput' 
import { devApi, prodApi } from '../data/api';

const url = prodApi.clients

const Directory = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDataSQLSecure(url);
        setData(data);
      } catch (error) {
        console.error("Error fetching client data:", error);
        throw error;
      }
    };

    fetchData();
  }, []);

  const theme = useTheme()
  const colours = tokens(theme.palette.mode)

  const columns = [
    {field: "id", headerName: "ID", flex: 0.5},
    {field: "name", headerName: "Name", flex: 1, cellClassName: 'name-column--cell'},
    {field: "company", headerName: "Company", flex: 1},
    {field: "email", headerName: "Email", flex: 1},
    {field: "phone", headerName: "Phone Number", flex: 1},
    {field: "address", headerName: "Address", flex: 1},
    {field: "city", headerName: "City", flex: 1},
    {field: "country", headerName: "Country", flex: 1},
  ]

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Header title="CLIENT DIRECTORY" subtitle="List of Client Contacts"/>
        <InputButton 
          position="fixed"
          form={<DirectoryInput/>}
          icon={<AddOutlinedIcon/>}
          width="45%"
          height="auto"
          top="10vh"
          bottom="none"
          right="5%"
          left="none"
        />
      </Box>
      <Box
        m="40px 0 0 0"
        height="60vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colours.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colours.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colours.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colours.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colours.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colours.grey[100]} !important`
          }
        }}
      >
        <DataGrid 
          checkboxSelection
          rows={transformData(data)}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  )
}

export default Directory