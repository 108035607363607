import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme"
import { countFormat } from "../functions";

const NotificationIcon = () => {
    const count = 10
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);

    return (
        <Box
            display="flex"
            width="25px"
            height="25px"
            justifyContent="center"
            border="solid 1px white"
            borderRadius="50%"
            backgroundColor={colours.redAccent[500]}
            zIndex= "2"
            position="absolute"
            alignItems="center"
            right="0"
            top="0"
            sx={{ 
                transform:"translate(50%,-50%)"
             }}
        >
            <Typography
                variant="body2"
                fontWeight="bold"
                color="white"
            >
                {countFormat(count)}
            </Typography>
        </Box>
    )
}

export default NotificationIcon