import React from 'react'
import { Box, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../theme";

const CustomAccordion = ({data}) => {
  const theme = useTheme();
  const colours = tokens(theme.palette.mode);
  return (
      <Box>
        {data.map((item, index) => (
        <Accordion 
          key = {item.id}
          defaultExpanded = {false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={colours.greenAccent[500]} variant="h7">
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
            <Box display="flex" justifyContent="end" alignItems="center" mb="20px" mr="20px">
            <img
                    alt={item.altImage}
                    width="60%"
                    src={item.image}
                    style={{ cursor: "pointer", borderRadius: "4px" }}
                  />
            </Box>
            <Typography 
              variant="body2" 
              sx={{ 
                display: "flex",
                width: "60%"
              }}
              >
              {item.description}
            </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        ))}
      </Box>
  );
};

export default CustomAccordion;