export const devApi = {
  login: "http://localhost:6335/login",
  user: "http://localhost:6335/user",
  calendar: "http://localhost:6335/calendardata",
  clients: "http://localhost:6335/clientdata",
  reports: "http://localhost:6335/reportsdata",
  dev: "http://localhost:6335/devdata",
  team: "http://localhost:6335/userdata",
};

export const prodApi = {
  login: "https://data.6334a.com/login",
  user: "https://data.6334a.com/user",
  calendar: "https://data.6334a.com/calendardata",
  clients: "https://data.6334a.com/clientdata",
  reports: "https://data.6334a.com/reportsdata",
  dev: "https://data.6334a.com/devdata",
  team: "https://data.6334a.com/userdata",
};
