import React from 'react'
import { Box } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import Header from '../components/Header'
import ButtonContainer from '../components/ButtonContainer';
import NotificationIcon from '../components/NotificationIcon';

const Office = () => {

  return (
    <>
    <Box m="20px">
      <Header
        title="OFFICE" 
        subtitle="Welcome to the Office"/>
    </Box>
    <Box 
      m="20px" 
      height="75vh" 
      display="grid" 
      gridTemplateColumns="repeat(2, 1fr)"
    >
      <Box 
        display="grid" 
        gridTemplateRows="repeat(2, 1fr)"
      >
        <ButtonContainer 
          title="Calendar"
          to="/office/calendar"
          icon={<CalendarMonthOutlinedIcon />}
        />
        <ButtonContainer 
          title="Extra"
          to="/office/extra"
        />
      </Box>
      <Box 
        display="grid" 
        gridTemplateRows="repeat(3, 1fr)"
      >
        <ButtonContainer 
          title="KPIs"
          to="/office/kpis"
          icon={<TimelineOutlinedIcon />}
        />
        <ButtonContainer 
          title="Projects(Mapping)"
          to="/office/projects"
          icon={<MapOutlinedIcon/>}
        />
        <Box 
          display="grid" 
          gridTemplateRows="repeat(2, 1fr)" 
          gridTemplateColumns="repeat(3, 1fr)"
        >
          <ButtonContainer
          title="Messaging" 
          icon={ <ForumOutlinedIcon /> }
          notification={<NotificationIcon/>}
          to="/office/messaging"
          >
            <NotificationIcon/>
          </ButtonContainer>
          <ButtonContainer 
          title="Tasks"
          to="/office/tasks"
          icon={<ChecklistOutlinedIcon />}
        />
          <ButtonContainer 
          title="Forms"
          to="/office/forms"
          icon={<DocumentScannerOutlinedIcon />}
        />
          <ButtonContainer 
          title="Apps"
          to="/office/apps"
          icon={<AppShortcutOutlinedIcon />}
        />
        <ButtonContainer 
          title="Reports"
          to="/office/reports"
          icon={<AddchartOutlinedIcon />}
        />
        <ButtonContainer 
          title="Requests"
          to="/office/requests"
          icon={<CollectionsBookmarkOutlinedIcon />}
        />
        </Box>
      </Box>
    </Box>
    </> 
  )
}

export default Office