import axios from "axios";

const fetchDataSQL = async (url, setData) => {
  try {
    const res = await axios.get(url);
    let data = res.data;

    setData(data);
  } catch (err) {
    console.error("Error retrieving data:", err);
  }
};

const fetchDataSQLSecure = async (url) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching client data:", error);
    throw error;
  }
};
// Transform Personnel Data
const transformData = (data) => {
  return data.map((item) => {
    const fullName = `${item.firstName} ${item.lastName}`;
    const transformedItem = { ...item, name: fullName };
    delete transformedItem.firstName;
    delete transformedItem.lastName;
    return transformedItem;
  });
};

// Calendar Entry Delete
const handleEventClick = (selected) => {
  if (
    window.confirm(
      `Are you sure you want to delete the event '${selected.event.title}'`
    )
  ) {
    selected.event.remove();
  }
};

// Calendar New Entry
const handleDateClick = (selected) => {
  const title = prompt("Please enter a new title for your event");
  const calendarApi = selected.view.calendar;
  calendarApi.unselect();

  if (title) {
    calendarApi.addEvent({
      id: `${selected.dateStr}-${title}`,
      title,
      start: selected.startStr,
      end: selected.endStr,
      allDay: selected.allDay,
    });
  }
};

const countFormat = (c) => {
  if (c > 9) {
    return "9+";
  } else {
    return `${c}`;
  }
};

const handleClientSubmit = (url, values, setData) => {
  axios
    .post(url, values)
    .then((res) => {
      console.log("Success", res.data);
      setData({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        country: "",
      });
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleChange = (e, data, setData) => {
  setData({
    ...data,
    [e.target.name]: e.target.value,
  });
};

const getUniqueValues = (array, key) => {
  return [...new Set(array.map((item) => item[key]))];
};

export {
  transformData,
  fetchDataSQL,
  fetchDataSQLSecure,
  handleEventClick,
  handleDateClick,
  countFormat,
  handleClientSubmit,
  handleChange,
  getUniqueValues,
};
