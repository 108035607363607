import React, { useContext } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { AuthContext } from "../contexts/AuthContext";
import { tokens } from "../theme";

const ProfileInput = () => {
    const { loggedIn, isLoggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const theme = useTheme()
    const colours = tokens(theme.palette.mode);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setLoggedIn(false);
        setIsLoggedIn({
            firstName: "",
            lastName: "",
            email: "",
            title: "",
            avatar: "",
          });
    }

    return (
        <>
        {loggedIn && (
            <Box m="30px">
                <Typography
                    variant="body2"
                    color={colours.greenAccent[400]}
                    sx={{ 
                        display:"flex",
                        justifyContent:"center",
                     }}
                >
                    {isLoggedIn.email}
                </Typography>
                <Box
                    m="20px" 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center"
                >
                    <img
                    alt="profile-user"
                    width="40%"
                    src={isLoggedIn.avatar}
                    style={{ 
                        cursor: "pointer", 
                        borderRadius: "50%",
                        backgroundColor: "black" 
                        }}
                    />
                </Box>
                <Typography
                    mb="40px"
                    variant="h5"
                    color={colours.grey[100]}
                    sx={{ 
                        display:"flex",
                        justifyContent:"center",
                     }}
                >
                    Hello, {isLoggedIn.firstName}
                </Typography>
                <Typography
                    m="10px"
                    variant="body"
                    color={colours.grey[100]}
                    sx={{ 
                        display:"flex",
                        justifyContent:"center",
                        "&:hover": {
                            cursor: "pointer",
                            color: `${colours.greenAccent[400]}`
                          }
                     }}
                >
                    Change Profile Image
                </Typography>
                <Typography
                    m="10px"
                    variant="body"
                    color={colours.grey[100]}
                    sx={{ 
                        display:"flex",
                        justifyContent:"center",
                        "&:hover": {
                            cursor: "pointer",
                            color: `${colours.greenAccent[400]}`
                          }
                     }}
                >
                    Change Password
                </Typography>
                <Box 
                    display="flex" 
                    justifyContent="center" mt="20px">
                    <Button 
                        onClick={handleLogout} 
                        color="alternate" 
                        variant="contained"
                    >
                        Logout
                    </Button>
                </Box>   
            </Box>
        )}    
        </>
    )
}

export default ProfileInput