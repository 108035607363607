import React from 'react'
import { Box, useTheme, Typography } from '@mui/material'
import { tokens } from "../theme"

const Container = ({ title }) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    return (
      <>
        <Box
          m="5px"
          display="flex"
          width="auto"
          height="auto"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          backgroundColor={colours.primary[400]}
          sx={{ 
            "&:hover": {
              cursor: "pointer",
              opacity: "0.75",
           }
          }}
        >
          <Typography variant="body2" color={colours.grey[100]}>{title}</Typography>
        </Box>
      </>
    )
  }

  export default Container