import React, {useState} from "react";
import { Box, IconButton } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import InputModal from "../modals/InputModal";

const InputButton = ({ form, position, icon, width, height, top, bottom, right, left}) => {
    const [openModal, setOpenModal] = useState(false);
    return (
            <Box
                mr="2.5%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="rgb(64, 24, 99)"
                onClick={() => {
                    setOpenModal(true);
                  }}
                sx={{
                    cursor:"pointer",
                  }}
            >
                <IconButton>
                    {icon}
                </IconButton>
                <InputModal 
                    open={openModal} 
                    onClose={() => setOpenModal(false)}
                    form={form}
                    position={position}
                    width={width}
                    height={height}
                    top={top}
                    bottom={bottom}
                    right={right}
                    left={left}
                />
            </Box>
    )
}

export default InputButton