import React, {useState, useEffect} from 'react'
import { Box } from "@mui/material";
import Header from "../components/Header";
import CustomAccordion from '../components/CustomAccordion';
import { fetchDataSQLSecure } from '../functions';
import { devApi, prodApi } from '../data/api';

const url = prodApi.dev

const Development = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDataSQLSecure(url);
        setData(data);
      } catch (error) {
        console.error("Error fetching client data:", error);
        throw error;
      }
    };

    fetchData();
  }, []); 
  
  return (
    <Box m="20px">
      <Header title="DEV+" subtitle="Programmes, Services and Products in Development" />
      <Box>
        <CustomAccordion data={data}/>
      </Box>
      
    </Box>
  );
};

export default Development;