import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Container from "../components/Container";

const Forms = () => {
    return (
        <>
        <Box m="20px">
            <Header title="FORMS" subtitle="Fill and Submit"/>
        </Box>
        <Box 
        m="20px" 
        height="75vh" 
        display="grid" 
        gridTemplateColumns="1fr 2fr"
      >
        <Container 
          title="Menu"
        />
        <Container 
          title="Body"
        />
      </Box>
      </>
        )
}

export default Forms