import React from "react";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { tokens } from "../theme";

const InputModal = ({ open, onClose, form, position, width, height, top, bottom, right, left }) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    if (!open) return null;
  
  return (
    <>
        <Box
            backgroundColor={colours.primary[400]}
            position={position}
            width={width}
            height={height}
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            border="solid black 1px"
            borderRadius="4px"
            onClose={onClose}
            onClick={(e) => {
                e.stopPropagation();
            }}
            sx={{ 
                zIndex: 2,
                
             }}
        >
            <IconButton
                onClick={onClose}
                color={colours.primary[400]}
                sx={{ 
                zIndex: "3",
                position:"absolute",
                alignItems:"flex-end",
                right:"1%",
                top:"1%",
                "&:hover": {
                    color:'red'
                }
                }}
            >
                <CloseOutlinedIcon />
            </IconButton>
            <Box
                onClick={onClose}
            >
                {form}
            </Box>  
        </Box>
    </>
  );
};

export default InputModal;