export const teamData = [
  {
    id: 1,
    name: "Fisayo Okunsanya",
    email: "fisayo@6334group.net",
    phone: "0802 000 0001",
    access: "admin",
  },
  {
    id: 2,
    name: "Maryjane Ugbaja",
    email: "maryjane@6334group.net",
    phone: "0802 000 0002",
    access: "manager",
  },
  {
    id: 3,
    name: "Chizoba Ezirim",
    email: "zoba@6334group.net",
    phone: "0802 000 0003",
    access: "manager",
  },
  {
    id: 4,
    name: "David Ebenezer",
    email: "david@6334group.net",
    phone: "0802 000 0004",
    access: "manager",
  },
  {
    id: 5,
    name: "juliet Mabi",
    email: "juliet@6334group.net",
    phone: "0802 000 0005",
    access: "user",
  },
  {
    id: 6,
    name: "Bolutife Dosunmu",
    email: "bolu@6334group.net",
    phone: "0802 000 0006",
    access: "user",
  },
  {
    id: 7,
    name: "Adepero Nathan",
    email: "adepero@6334group.net",
    phone: "0802 000 0007",
    access: "user",
  },
  {
    id: 8,
    name: "Godwill Kinding",
    email: "godwill@6334group.net",
    phone: "0802 000 0008",
    access: "user",
  },
  {
    id: 9,
    name: "Kanyinsola Adeniran",
    email: "kanyin@6334group.net",
    phone: "0802 000 0009",
    access: "admin",
  },
];

export const clientData = [
  {
    id: 1,
    firstName: "Bill",
    lastName: "Gates",
    company: "Microsoft",
    email: "bill@microsoft.com",
    phone: "(617)205 3479",
    address: "34, Tarantula Drive",
    city: "Anaheim, CA",
    country: "USA",
  },
  {
    id: 2,
    firstName: "Aliko",
    lastName: "Dangote",
    company: "Dangote Group",
    email: "aliko@dangotegroup.com",
    phone: "0805 001 1234",
    address: "47, Potato Lane",
    city: "Banana Island, Lagos",
    country: "Nigeria",
  },
  {
    id: 3,
    firstName: "Elon",
    lastName: "Musk",
    company: "Tesla",
    email: "elon@tesla.net",
    phone: "345 6894 234",
    address: "1, Amapiano Street",
    city: "Johannesburg",
    country: "South Africa",
  },
  {
    id: 4,
    firstName: "Jeff",
    lastName: "Bezos",
    company: "Amazon",
    email: "jeff@amazon.us",
    phone: "(756)245 3004",
    address: "Amazon Towers",
    city: "Seattle, WA",
    country: "USA",
  },
  {
    id: 5,
    firstName: "Goke",
    lastName: "Dele",
    company: "Quest Oil and Gas",
    email: "goke@quest.ng",
    phone: "0802 647 5629",
    address: "15, Admiralty Way",
    city: "Lekki, Lagos",
    country: "Nigeria",
  },
];

export const savedEvents = [
  {
    id: 1,
    calendar: "Birthdays",
    title: "Fisayo",
    date: "2023-09-28",
  },
  {
    id: 2,
    calendar: "Meetings",
    title: "Team Meeting",
    date: "2023-09-18",
  },
  {
    id: 3,
    calendar: "Holidays",
    title: "Christmas Eve",
    date: "2023-12-24",
  },
  {
    id: 4,
    calendar: "Holidays",
    title: "Christmas",
    date: "2023-12-25",
  },
  {
    id: 5,
    calendar: "Holidays",
    title: "Boxing Day",
    date: "2023-12-26",
  },
  {
    id: 6,
    calendar: "Holidays",
    title: "New Years Day",
    date: "2024-01-01",
  },
  {
    id: 7,
    calendar: "Birthdays",
    title: "Juliet",
    date: "2024-07-15",
  },
  {
    id: 8,
    calendar: "Birthdays",
    title: "Chizoba",
    date: "2024-05-19",
  },
  {
    id: 9,
    calendar: "Birthdays",
    title: "Maryjane",
    date: "2024-05-17",
  },
  {
    id: 10,
    calendar: "FEC",
    title: "Apostles in the Marketplace",
    date: "2023-10-15",
  },
  {
    id: 11,
    calendar: "Projects",
    title: "Quest",
    date: "2024-03-09",
  },
  {
    id: 12,
    calendar: "Meetings",
    title: "Analytics Team",
    date: "2023-10-21",
  },
  {
    id: 14,
    calendar: "Training",
    title: "Zenith Bank",
    date: "2023-10-12",
  },
];

export const devData = [
  {
    title: "IT and Technology Consulting",
    image: "../images/drone.png",
    altImage: "drone",
    description:
      "Advise on technology solutions, system integration, cybersecurity, and digital transformation to leverage technology for business growth and improved operations.",
  },
  {
    title: "Supply Chain and Logistics Optimization",
    image: "../images/logistics.png",
    altImage: "truck",
    description:
      "Evaluate supply chain processes, identify bottlenecks, and design strategies for cost reduction, inventory management, and improved distribution efficiency.",
  },
  {
    title: "Sustainability and Environmental Consulting",
    image: "../images/environment.png",
    altImage: "environment",
    description:
      "Assist businesses in adopting environmentally sustainable practices, meeting regulatory requirements, and integrating sustainability into their operations and strategies.",
  },
  {
    title: "Organizational Restructuring",
    image: "../images/organization.png",
    altImage: "organization",
    description:
      "Evaluate organizational structure, processes, and workflows, and recommend changes to optimize efficiency, enhance collaboration, and align with business goals.",
  },
];
