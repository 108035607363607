import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { DataContext } from "../contexts/DataContext";
import { tokens } from "../theme";
import MultiselectDropdown from "./DropdownMenu";
import { fetchDataSQLSecure, getUniqueValues } from "../functions";
import { devApi, prodApi } from "../data/api";

const url = prodApi.calendar;

const CalendarControl = ({setEvents}) => {
    const { dispatch } = useContext(DataContext);

    // State Management
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [display, setDisplay] = useState("")

    // Fetch and Set Data
    useEffect(() => {
        const fetchData = async () => {
            try {
            const data = await fetchDataSQLSecure(url);
            setData(data);
            } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
            }
        };
        if (data !== null) {
            fetchData();
        }
    }, [data]);

    // Filtered Data
    useEffect(() => {
        const newData = data.filter((row) => {
            const match = selected.length === 0 || selected.includes(row.calendar);
            return match;
        });
        // setFilteredCalendarData(newData);
        setEvents(newData);
        dispatch({
            type: "UPDATE_EVENTS",
            payload: newData,
        });
    }, [data, selected, dispatch]);

    // Select Functionality
    const handleSelect = (selectedList) => {
        setSelected(selectedList);
        setDisplay(`${selectedList.length} options selected`);
    };

    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    
    return (
        <Box
            flex="1 1 20%"
            mb="10px"
            backgroundColor={colours.primary[400]}
            p="15px"
            borderRadius="4px"
            sx={{ 
                cursor:"pointer"
             }}
        >
            <MultiselectDropdown 
                label="Filter your Calendar"
                id="calendar"
                placeholder="Calendar"
                options={getUniqueValues(data, "calendar")}
                selectedValues={selected}
                onChange={handleSelect}
            />
        </Box>
        
    )
}

export default CalendarControl;