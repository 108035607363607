import React from 'react'
import { Box, Button, IconButton, useTheme, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { tokens } from "../theme"

const ButtonContainer = ({ title, to, component, icon, notification }) => {
    const theme = useTheme();
    const colours = tokens(theme.palette.mode);
    return (
      <Button 
        component={Link} 
        to={`${to}`}
      >
      <Box
        display="flex"
        position="relative"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        backgroundColor={colours.primary[400]}
        sx={{ 
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.01)",
            opacity: "0.75",
            backgroundColor: `${colours.greenAccent[400]}`
          }
        }}
      >
        {component}
        {notification}
        <IconButton
          color={colours.grey[100]}
          sx={{ 
            opacity: "0.5",
            // zIndex: "2",
            position:"absolute",
            alignItems:"flex-end",
            right:"5%",
            bottom:"5%"
           }}
        >
          {icon}
        </IconButton> 
        <Typography variant="body2" color={colours.grey[100]}>{title}</Typography>
      </Box>
      </Button>
    )
  }

  export default ButtonContainer