import React, { useState, useContext } from 'react'
import { Box } from '@mui/material'
import Header from '../components/Header'
import CalendarControl from '../components/CalendarControl';
import CalendarSidebar from '../components/CalendarSidebar';
import CalendarMain from '../components/CalendarMain';
import { DataContext } from '../contexts/DataContext';

const Calendar = () => {
  const {state} = useContext(DataContext)
  const {filteredCalendarData} = state
  const [events, setEvents] = useState(filteredCalendarData);
  
  return (
    <>
    <Box m="20px">
      <Header title="CALENDAR" subtitle="Stay up to date with Calendar" />
    </Box>
    <Box 
      m="20px"
      height="75vh" 
      display="grid" 
      gridTemplateColumns="1fr 3fr"
    >
      <Box
        display="grid"
        gridTemplateRows="1fr 6fr"
      >
        <CalendarControl
          events={events}
          setEvents={setEvents}
        />
        <CalendarSidebar
          events={events}
        />
      </Box>
      <CalendarMain
        events={events} 
        setEvents={setEvents}
      />
    </Box>
    </>
  );
};
  
export default Calendar;