import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Container from "../components/Container";

const Requests = () => {
    return (
        <>
        <Box m="20px">
            <Header title="REQUESTS" subtitle="Product and Service Requests"/>
        </Box>
         <Box 
         m="20px" 
         height="75vh" 
         display="grid" 
         gridTemplateColumns="1fr 2fr"
       >
         <Container 
           title="Menu"
         />
         <Container 
           title="Body"
         />
       </Box>
       </>
        )
}

export default Requests