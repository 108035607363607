import React, { createContext, useReducer } from "react";

const initialState = {
  filteredCalendarData: [],
};

export const DataContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_EVENTS":
      return {
        ...state,
        filteredCalendarData: action.payload,
      };
    default:
      return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
