import React, {useState} from "react";
import axios from "axios";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { devApi, prodApi } from "../data/api";

const url = prodApi.clients

const userSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    company: yup.string().required("Required"),
    email: yup.string().email("Invalid Email Address").required("Required"),
    phone: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    country: yup.string().required("Required"),
})

const DirectoryInput = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [data, setData] = useState(
        {
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            country: "",
        }
    )
    
    const handleSubmit = (values) => {

    axios
      .post(url, values)
      .then((res) => {
        console.log("Success", res.data);
        setData({
            firstName: "",
            lastName: "",
            company: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            country: "",
        });
      })
      .catch((err) => {
        console.error(err);
      });
    };

    const handleChange = (e, values) => {
        setData(e.target.values)
        console.log(values)
    }
    return (
        <Box m="30px">
            <Header title="NEW CLIENT" subtitle="Add a new client to the directory"/>

            <Formik
                onSubmit={handleSubmit}
                onChange={handleChange}
                initialValues={data}
                validationSchema={userSchema}
            >
                {({ 
                    values, 
                    errors, 
                    touched, 
                    handleBlur, 
                    handleChange, 
                    handleSubmit 
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="First Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstName}
                                name="firstName"
                                error={!!touched.firstName && !!errors.firstName}
                                helperText={touched.firstName && errors.firstName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Last Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastName}
                                name="lastName"
                                error={!!touched.lastName && !!errors.lastName}
                                helperText={touched.lastName && errors.lastName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Company"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.company}
                                name="company"
                                error={!!touched.company && !!errors.company}
                                helperText={touched.company && errors.company}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 1" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address}
                                name="address"
                                error={!!touched.address && !!errors.address}
                                helperText={touched.address && errors.address}
                                sx={{ gridColumn: "span 4" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="City"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                name="city"
                                error={!!touched.city && !!errors.city}
                                helperText={touched.city && errors.city}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField 
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Country"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.country}
                                name="country"
                                error={!!touched.country && !!errors.country}
                                helperText={touched.country && errors.country}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Client
                            </Button>
                        </Box>
                    </form>
                )}

            </Formik>
        </Box>
    )
}

export default DirectoryInput