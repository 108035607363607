import React from "react";
import { Box } from "@mui/material";
import Header from "../components/Header";

const Messaging = () => {
    return (
        <Box m="20px">
            <Header title="MESSAGING" subtitle="Keep in touch with your Colleagues"/>
        </Box>
        )
}

export default Messaging